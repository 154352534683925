<template>
  <v-card>
    <v-container>
      <v-card-title class="primary--text">
        <v-col cols="8">Proceso importación de topes</v-col>
      </v-card-title>
      <v-card-text>
        <!-- Proceso de importación -->
        <v-row cols="12" class="py-0">
          <v-col cols="7">
            <v-file-input
              ref="file"
              v-model="file"
              :disabled="loading"
              :loading="loading"
              id="fileUpload"
              type="file"
              dense
              label="Excel"
              placeholder="Seleccionar el archivo Excel"
              accept=".xls,.xlsx,.csv"
              outlined
              :show-size="1000"
              @change="importExcel"
              prepend-icon="mdi-file-find-outline"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  :color="!loading ? 'primary' : 'grey lighten-1'"
                  dark
                  label
                  small
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2" class="py-0">
            <v-btn
              color="primary"
              class="no-upper-case to-right"
              outlined
              @click="openModalSeeFormatoArchivo()"
            >
              Ver formato
            </v-btn></v-col
          >
          <v-btn class="mr-4" outlined @click="closeModalExcel()">
            Cerrar
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!enableProcess || !file"
            :loading="loadingProcess"
            @click="saveProcesoImportacion(excelDataTranslated)"
          >
            Procesar
          </v-btn>
        </v-row>
      </v-card-text>
    </v-container>
    <v-dialog
      v-if="modalExportarExcel"
      v-model="modalExportarExcel"
      max-width="55%"
      persistent
    >
      <v-card>
        <v-container>
          <v-card-title>
            <span class="pl-1 primary--text"
              >Se generará un archivo con el detalle de los registros con error.
            </span>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="to-right"
              text
              @click="exportExcelError()"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <FormatoArchivoDialog
      :formatoTitle="formatoTitle"
      :headers="headers"
      :datosFormatoArchivo="datosFormatoArchivo"
      :dataToExport="dataToExport"
      :openFormatoArchivoDialog.sync="showSeeFormatModal"
    />
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import helpersExcel from "@/utils/helpers/importExportExcel";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import FormatoArchivoDialog from "@/components/shared/FormatoArchivoDialog.vue";
import pako from "pako";

export default {
  name: "NuevoProcesoImportacion",
  directives: { mask },
  components: { FormatoArchivoDialog },
  data() {
    return {
      rules: rules,
      loading: false,
      entFinId: null,
      comprobantesAGenerar: [],
      excelData: [],
      modalExportarExcel: false,
      archivoEnProceso: false,
      file: null,
      isFormSIROValid: false,
      fechaDesdeSIRO: null,
      fechaDesdeTextFieldSIRO: null,
      menuDesdeSIRO: false,
      fechaHastaSIRO: null,
      fechaHastaTextFieldSIRO: null,
      menuHastaSIRO: false,
      fileProcesoImportacion: null,
      registrosError: [],
      fileName: null,
      formatoTitle: "Formato de archivo",
      headers: [],
      datosFormatoArchivo: [],
      enableProcess: false,
      loadingProcess: false,
      showSeeFormatModal: false,
      excelDataTranslated: [],
      dataToExport: {
        formatoName: "Proceso importación topes modelo",
        data: [
          {
            ["Convenio"]: "Convenio1",
            ["Plan"]: "Plan1",
            ["Sexo"]: "F",
            ["Edad desde"]: "0",
            ["Edad hasta"]: "120",
            ["Nomenclador"]: "Prestaciones Médicas",
            ["Codigo desde"]: "420101",
            ["Codigo hasta"]: "420101",
            ["Agrupacion"]: "",
            ["Grupo"]: "",
            ["Vigencia"]: "1/1/2024",
            ["Topes"]: "4",
            ["Dias"]: "50",
            ["Tipo de autorizacion"]: "HABILITADA CON AUDITORÍA PREVIA",
            ["Tope año calendario"]: "NO",
            ["Controlar codigo rango"]: "SI",
            ["Aplicar tope por grupo familiar"]: "SI"
          },
          {
            ["Convenio"]: "Convenio2",
            ["Plan"]: "Plan2",
            ["Sexo"]: "M",
            ["Edad desde"]: "0",
            ["Edad hasta"]: "120",
            ["Nomenclador"]: "",
            ["Codigo desde"]: "",
            ["Codigo hasta"]: "",
            ["Agrupacion"]: "COSEGUROS",
            ["Grupo"]: "Cardiologia",
            ["Vigencia"]: "1/1/2024",
            ["Topes"]: "4",
            ["Dias"]: "50",
            ["Tipo de autorizacion"]: "INHABILITADA",
            ["Tope año calendario"]: "NO",
            ["Controlar codigo rango"]: "NO",
            ["Aplicar tope por grupo familiar"]: "NO"
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions({
      getFormatoProcesoImportacionTopes:
        "prestaciones/getFormatoProcesoImportacionTopes",
      saveProcesoImportacionTopes: "prestaciones/saveProcesoImportacionTopes",
      setAlert: "user/setAlert"
    }),
    closeModalExcel() {
      this.$emit("toggleModalProcesoImportacionTopes");
    },
    async importExcel() {
      if (this.file) {
        try {
          this.getArchivo(0);
          const archivoValido = this.verifArchivo();
          if (archivoValido) {
            this.loading = false;
            this.fileName = this.file.name;
            this.excelDataTranslated = [];
            this.excelData[0].filas.forEach(x => {
              this.excelDataTranslated.push({
                os: x["Convenio"],
                plan: x["Plan"],
                sexo: x["Sexo"],
                edadDesde: x["Edad desde"],
                edadHasta: x["Edad hasta"],
                nomenclador: x["Nomenclador"],
                codigoDesde: x["Codigo desde"],
                codigoHasta: x["Codigo hasta"],
                agrupra: x["Agrupacion"],
                gru: x["Grupo"],
                vigenciaToShow: helpersExcel.translateDate(x["Vigencia"]),
                //Vigencia: x["Vigencia"],
                tope: x["Topes"],
                dias: x["Dias"],
                tipoAutorizacion: x["Tipo de autorizacion"],
                anioCalendario: x["Tope año calendario"],
                controlarCodRango: x["Controlar codigo rango"],
                aplicarTopePorGrupo: x["Aplicar tope por grupo familiar"]
              });
            });
            this.setAlert({
              type: "warning",
              message:
                "Aviso: al procesar el archivo, se reemplazará la configuración actual y solamente quedará la del archivo."
            });
            this.enableProcess = true;
          } else {
            setTimeout(this.importExcel, 2000);
          }
        } catch (error) {
          // contempla el error de formato dentro del importExportExcel.js
          this.setAlert({ type: "warning", message: error });
          this.$refs["file"].reset();
        }
      }
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    deflateData(data) {
      return new Promise((resolve, reject) => {
        const encoder = new TextEncoder();
        const byteArray = encoder.encode(data);
        // Comprimir los datos
        const compressedArray = pako.deflate(byteArray, { gzip: true });
        // Convertir a Blob
        const blob = new Blob([compressedArray], {
          type: "application/octet-stream"
        });
        // Leer el Blob como una URL de datos
        const reader = new FileReader();
        reader.onload = () => {
          // Resolver con los datos comprimidos en formato base64
          resolve(reader.result.split(",")[1]);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },
    async inflateData(compressedBase64) {
      return new Promise((resolve, reject) => {
        // Decodificar de base64
        const compressedData = atob(compressedBase64);

        // Convertir a Uint8Array
        const compressedArray = new Uint8Array(compressedData.length);
        for (let i = 0; i < compressedData.length; ++i) {
          compressedArray[i] = compressedData.charCodeAt(i);
        }

        // Descomprimir los datos
        const inflatedArray = pako.inflate(compressedArray, { gzip: true });

        // Convertir a cadena
        const inflatedString = new TextDecoder().decode(inflatedArray);

        // Convertir a objeto JSON
        const inflatedObject = JSON.parse(inflatedString);

        // Guardar los datos descomprimidos en this.registrosError
        this.registrosError = inflatedObject;
        resolve(inflatedObject);
      });
    },
    async saveProcesoImportacion(datosProceso) {
      this.loadingProcess = true;
      const jsonString = JSON.stringify(datosProceso);
      const compressedData = await this.deflateData(jsonString);
      try {
        const response = await this.saveProcesoImportacionTopes({
          datosComprimidos: compressedData,
          fileName: this.fileName.trim()
        });
        const responseComprimida = response.data.data.datosComprimidos;
        if (response.status === 200) {
          if (responseComprimida.length > 0) {
            await this.inflateData(responseComprimida);
            if (this.registrosError.length > 0) this.modalExportarExcel = true;
            else {
              this.setAlert({
                type: "success",
                message: "Procesado con éxito."
              });
              this.closeModalExcel();
              this.loading = false;
              this.loadingProcess = false;
              this.enableProcess = false;
            }
          }
        }
      } catch {
        this.loading = false;
        this.loadingProcess = false;
        this.enableProcess = false;
      }
    },
    getArchivo(rowsToIgnore) {
      // si el archivoEnProceso es false, significa que es la primera vez que llama al metodo
      if (this.archivoEnProceso == false) {
        this.loading = true;
        this.archivoEnProceso = true;
        this.$refs["file"].validate(true);
        if (this.$refs.file.validate()) {
          this.excelData = helpersExcel.excelImport(this.file, rowsToIgnore);
        }
      }
    },
    verifArchivo() {
      if (this.excelData[0]?.filas != undefined) {
        this.archivoEnProceso = false;
        return true;
      }
      return false;
    },
    exportExcelError() {
      let result = [];
      this.registrosError.forEach(x =>
        result.push({
          ["Convenio"]: x.Os,
          ["Plan"]: x.Plan,
          ["Sexo"]: x.Sexo,
          ["Edad desde"]: x.EdadDesde,
          ["Edad hasta"]: x.EdadHasta,
          ["Nomenclador"]: x.Nomenclador == null ? "" : x.Nomenclador,
          ["Codigo desde"]: x.CodigoDesde == null ? "" : x.CodigoDesde,
          ["Codigo hasta"]: x.CodigoHasta == null ? "" : x.CodigoHasta,
          ["Agrupación"]: x.Agrupra == null ? "" : x.Agrupra,
          ["Grupo"]: x.Gru == null ? "" : x.Gru,
          ["Vigencia"]: x.VigenciaToShow,
          ["Topes"]: x.Tope,
          ["Dias"]: x.Dias,
          ["Tipo de autorizacion"]: x.TipoAutorizacion,
          ["Tope año calendario"]: x.AnioCalendario,
          ["Controlar codigo rango"]: x.ControlarCodRango,
          ["Aplicar tope por grupo familiar"]: x.AplicarTopePorGrupo,
          ["Errores"]: x.Errores
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Errores registros");
      this.closeModalExcel();
    },
    async openModalSeeFormatoArchivo() {
      const formatoProceso = await this.getFormatoProcesoImportacionTopes();
      this.datosFormatoArchivo = formatoProceso;
      this.headers = [
        {
          text: "Nombre campo",
          align: "start",
          sortable: false,
          value: "nombreCampo"
        },
        {
          text: "Longitud máxima",
          align: "start",
          sortable: false,
          value: "longMax"
        },
        {
          text: "Formato",
          value: "formato",
          align: "start",
          sortable: false
        },
        {
          text: "Requerido",
          value: "requerido",
          align: "center",
          sortable: false
        },
        {
          text: "Descripción",
          value: "descripcion",
          align: "start",
          sortable: false
        }
      ];
      this.showSeeFormatModal = true;
    }
  }
};
</script>
