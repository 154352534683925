<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" />
    <v-data-table
      :headers="headers"
      :items="procesosImportacion"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:[`item.anulado`]="{ item }">
        <v-icon v-if="item.fecAnula !== null" small color="primary">
          {{ checkIcon }}
        </v-icon>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="search"
                :append-icon="searchIcon"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col v-if="canCreate" cols="4" align="end">
              <v-btn
                color="primary"
                class="to-right"
                @click="toggleModalNuevoProcesoImportacion()"
              >
                Nuevo
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-if="canAnular" v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <section style="display: inline" v-on="on">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                :disabled="!item.canAnular"
                @click="anularProceso(item.procId)"
              >
                {{ anularIcon }}
              </v-icon>
            </section>
          </template>
          <span v-if="!item.canAnular">No se puede anular</span>
          <span v-else>Anular proceso </span>
        </v-tooltip>
      </template></v-data-table
    >
    <v-dialog
      v-model="modalNuevoProcesoImportacion"
      v-if="modalNuevoProcesoImportacion"
      @keydown.esc="toggleModalNuevoProcesoImportacion"
      max-width="60%"
      persistent
    >
      <NuevoProcesoImportacionTopesGenerales
        @toggleModalProcesoImportacionTopes="toggleModalNuevoProcesoImportacion"
      ></NuevoProcesoImportacionTopesGenerales>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'35%'"
      :confirmButtonText="'Anular'"
      :openDelete.sync="showAnularModal"
      :isLoading="isLoadingAnular"
      @onDeleteItem="confirmAnularProceso()"
    />
  </v-container>
</template>

<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import NuevoProcesoImportacionTopesGenerales from "@/components/modules/prestaciones/configuracion/NuevoProcesoImportacionTopesGenerales.vue";

export default {
  name: "ProcesosImportacionTopesGenerales",
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    NuevoProcesoImportacionTopesGenerales
  },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setPermisos();
    this.setProcesosImportacionTopes();
  },
  data() {
    return {
      routeToGo: "TopesGenerales",
      title: "Procesos de importación topes generales",
      search: "",
      titleDelete: "¿Desea anular el proceso?",
      searchIcon: enums.icons.SEARCH,
      checkIcon: enums.icons.CHECK_OUTLINE,
      anularIcon: enums.icons.CIRCLE_NONE,
      seeIcon: enums.icons.SEE,
      modalProcesoImportacion: false,
      canCreate: false,
      canAnular: false,
      expanded: [],
      optionCode: enums.webSiteOptions.PROCESOS_IMPORTACION_TOPES_GENERALES,
      procesosImportacion: [],
      isLoadingAnular: false,
      headers: [
        {
          text: "Número proceso",
          align: "start",
          value: "procId",
          sortable: false
        },
        {
          text: "Fecha alta",
          align: "start",
          value: "fecAlta",
          sortable: false
        },
        {
          text: "Usuario alta",
          align: "start",
          value: "usuAlta",
          sortable: false
        },
        {
          text: "Nombre archivo",
          align: "start",
          value: "rutaArchivo",
          sortable: false
        },
        {
          text: "Anulado",
          align: "center",
          value: "anulado",
          sortable: false
        },
        {
          text: "Fecha anulado",
          align: "start",
          value: "fecAnula",
          sortable: false
        },
        {
          text: "Usuario anulado",
          align: "start",
          value: "usuAnula",
          sortable: false
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      procesoImportacionId: null,
      modalVerDetalleProcesoImportancion: false,
      showAnularModal: false,
      modalNuevoProcesoImportacion: false,
      idToAnular: null,
      allowedActions: null
    };
  },
  methods: {
    ...mapActions({
      getProcesosImportacionTopesGenerales:
        "prestaciones/getProcesosImportacionTopesGenerales",
      anularProcesoImportacionTopesGenerales:
        "prestaciones/anularProcesoImportacionTopesGenerales",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .ANULAR_PROCESO_IMPORTACION_TOPE:
            this.canAnular = true;
            break;
          case enums.modules.adminSistema.permissions
            .NUEVO_PROCESO_IMPORTACION_TOPE:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    async setProcesosImportacionTopes() {
      const procesos = await this.getProcesosImportacionTopesGenerales();
      this.procesosImportacion = procesos;
    },
    toggleModalNuevoProcesoImportacion() {
      this.modalNuevoProcesoImportacion = !this.modalNuevoProcesoImportacion;
      this.setProcesosImportacionTopes();
    },
    anularProceso(id) {
      this.idToAnular = id;
      this.showAnularModal = true;
    },
    async confirmAnularProceso() {
      try {
        this.isLoadingAnular = true;
        const response = await this.anularProcesoImportacionTopesGenerales(
          this.idToAnular
        );
        if (response.status === 200) {
          if (response.data.data)
            this.setAlert({ type: "success", message: "Anulado con éxito." });
          else
            this.setAlert({ type: "warning", message: "No se pudo anular." });
        }
        this.isLoadingAnular = false;
        this.showAnularModal = false;
        this.setProcesosImportacionTopes();
      } catch {
        this.isLoadingAnular = false;
        this.showAnularModal = false;
        this.setProcesosImportacionTopes();
      }
    }
  }
};
</script>
